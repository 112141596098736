<template>
  <Block>
    <div v-if="isLoading"><Loader /></div>
    <div v-else-if="error" class="text-danger">{{ error }}</div>
    <b-tabs v-else :class="{ 'tabs-content-hidden': !shown }" content-class="tab-content-scroll">
      <template #tabs-end>
        <Arrow :direction="shown ? 'top' : 'bottom'" class="tabs-show-arrow" @arrow-click="shown = !shown" />
      </template>
      <b-tab v-for="tab in tabs" :key="tab.code" :title="tab.title + ' (' + items[tab.code].length + ')'">
        <perfect-scrollbar>
          <table v-if="items[tab.code].length" class="plan-items">
            <tr v-for="item in items[tab.code]" :key="item.id" class="plan-item">
              <td class="plan-item-date">{{ formatDate(item[tab.date]) }}</td>
              <td class="plan-item-number"></td>
              <td class="plan-item-price currency">{{ item.sum | money }}</td>
              <td class="plan-item-title"><a v-if="item.source_link" :href="item.source_link" target="_blank">{{ item.title }}</a></td>
              <td><a v-if="item.source_link" :href="item.source_link" target="_blank"><Arrow direction="right" /></a></td>
            </tr>
          </table>
          <div v-else class="plan-items-empty"><Alert icon="warning">Нет сделок для отображения</Alert></div>
        </perfect-scrollbar>
      </b-tab>
    </b-tabs>
  </Block>
</template>

<script>
import moment from 'moment'
import Arrow from '@/components/ui/Arrow'
import Alert from '@/components/ui/Alert'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'EntityTabBlock',
  props: ['userId'],
  components: {
    Arrow,
    Alert
  },
  computed: {
    ...mapState('sale/settings', ['deal_mode']),
    ...mapGetters('sale/date', ['dateQuery'])
  },
  data () {
    return {
      isLoading: true,
      error: '',
      shown: true,
      tabs: [
        { title: 'Открытые сделки', code: 'opened', date: 'created_at' },
        { title: 'Оплаченные сделки', code: 'paid', date: 'success_date' }
      ],
      items: {
        paid: [],
        opened: []
      }
    }
  },
  async created () {
    await this.$store.dispatch('sale/settings/fetchSettings')
    const isStatusMode = this.deal_mode === 'status'

    if (isStatusMode) {
      this.tabs.splice(1, 0, {
        title: 'Выставленные счета',
        code: 'predicted',
        date: 'created_at'
      })

      this.items.predicted = []
    }

    try {
      this.items.paid = await this.$api.query(`sale/deal/success/${this.userId}${this.dateQuery}`)
      this.items.opened = await this.$api.query(`sale/deal/opened/${this.userId}${this.dateQuery}`)
      if (isStatusMode) {
        this.items.predicted = await this.$api.query(`sale/deal/predict/${this.userId}${this.dateQuery}`)
      }
    } catch (e) {
      this.error = 'Не удалось загрузить данные'
    }

    this.isLoading = false
  },
  methods: {
    formatDate (timestamp) {
      return moment(timestamp * 1000).format('DD.MM.YYYY')
    }
  }
}
</script>

<style scoped>
.plan-items {
  width: 100%;
}
.plan-item td {
  border-bottom: 1px solid var(--border-color);
  padding: 20px 0;
  font-size: 13px;
  line-height: 15px;
}
.plan-item td:last-child {
  width: 28px;
}
.plan-item-date {
  color: var(--text-color);
}
.plan-item-number, .plan-item-title {
  color: var(--blue-color);
}
.plan-item-price {
  font-weight: 600;
}
.plan-item-date,
.plan-item-number,
.plan-item-price {
  min-width: 100px;
  flex-shrink: 0;
}
.plan-items-empty {
  padding: 22px 0;
  border-bottom: 1px solid var(--border-color);
}
.tabs-show-arrow {
  position: relative;
}
</style>
