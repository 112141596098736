<template>
  <div>
    <TopPanel
      :back="!can('sale_plan') || id > 0"
      @back-click="$router.push({ name: 'sale/plan/progress' })"
      pageSwitcher
      actions
      @refresh-click="refreshUserPlanData()"
    />

    <div v-if="isLoading"><Loader /></div>
    <div v-else-if="error" class="text-danger">{{ error }}</div>
    <div v-else>
      <Block>
        <div class="block-head border-bottom pb-10">
          <div class="d-flex justify-content-between align-items-center">
            <div class="h1">{{ pageTitle }}</div>
            <DateSelect v-if="userCanSettings" @date-update="refreshUserPlanData()" />
          </div>
        </div>
        <PlanChart
          :plan="plan"
          :minPlan="minPlan"
          :fact="fact"
          :predict="predict"
        />
      </Block>
      <EntityTabBlock class="pb-30" :userId="userId" />
      <KpiBlock :userId="userId" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KpiBlock from '@/components/sale/KpiBlock'
import EntityTabBlock from '@/components/sale/EntityTabBlock'
import PlanChart from '@/components/sale/Chart'
import TopPanel from '@/components/sale/TopPanel'
import CanMixin from '@/mixins/CanMixin'
import DateSelect from '@/components/sale/DateSelect'

export default {
  name: 'Personal',
  props: ['id'],
  mixins: [CanMixin],
  components: {
    KpiBlock,
    EntityTabBlock,
    PlanChart,
    TopPanel,
    DateSelect
  },
  data () {
    return {
      isLoading: true,
      error: '',
      plan: 0,
      minPlan: 0,
      fact: 0,
      predict: 0,
      pageTitle: 'Личный план'
    }
  },
  computed: {
    ...mapGetters('auth', ['current']),
    ...mapGetters('sale/date', ['dateQuery']),
    userCanSettings () {
      return this.can('sale_settings')
    },
    userId () {
      if (!this.userCanSettings) {
        return this.current.id
      }
      return this.id ?? this.current.id
    }
  },
  watch: {
    $route () {
      this.fetchUserPlanData()
    }
  },
  async created () {
    if (!this.id && !this.can('sale_plan')) {
      if (!this.userCanSettings) { // if user role is owner then redirect to dashboard page
        this.$router.push({ name: 'sale/dashboard' })
        return
      } else if (this.userCanSettings) { // if user role is sale_head then redirect to company progress page
        this.$router.push({ name: 'sale/plan/progress' })
        return
      }
    }
    await this.fetchUserPlanData()

    if (this.id) {
      await this.$api.query(`user/${this.id}`).then(response => {
        this.pageTitle += ` (${response.name})`
      })
    }
  },
  methods: {
    async refreshUserPlanData () {
      this.$bus.$emit('refreshData')
      await this.fetchUserPlanData()
    },
    async fetchUserPlanData () {
      this.isLoading = true

      try {
        const result = await this.$api.query(`sale/user-plan/${this.userId}${this.dateQuery}`)
        if (result && result.status !== 'accepted' && this.userId === this.current.id) {
          await this.$router.push({ name: 'sale/planning' })
          // alert('redirect to sale/planning')
          return
        }
        this.plan = result.sum
        this.minPlan = result.min_sum
        this.fact = result.fact_sum
        this.predict = result.predict_sum
      } catch (e) {
        this.error = 'Не удалось получить данные'
      }

      this.isLoading = false
    }
  }
}
</script>
